import React from 'react';

const Footer = () => {
    return (
        <footer style={{backgroundColor: '#202020', color: 'white', textAlign: 'center', padding: '10px', marginTop: 'auto'}}>
            <p>© 2023 Miraculous.top</p>
            <p>admin@miraculous.to</p>
        </footer>
    );
}

export default Footer;