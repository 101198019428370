import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import './Countdown.css';

function Countdown() {

    const newssignup = () => {
        swal({
            title: "Follow us on social media",
            buttons: {
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    closeModal: true
                }
            },
            content: {
                element: "div",
                attributes: {
                    innerHTML: `
                        <a href="https://t.me/miraculous_top" style="text-decoration: none;">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png" alt="Telegram" style="width: 50px; height: 50px; margin-right: 10px;">
                        </a>
                        <a href="https://twitter.com/your-profile" target="blank" style="text-decoration: none;">
                            <img src="https://cdn.logojoy.com/wp-content/uploads/20210422095037/discord-mascot.png" alt="Discord" style="width: 50px; height: 50px; margin-right: 10px;">
                        </a>
                        <a href="https://instagram.com/your-profile" target="blank" style="text-decoration: none;">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png" alt="Instagram" style="width: 50px; height: 50px; margin-right: 10px;">
                        </a>
                    `
                }
            }
        });
    };
    const calculateTimeLeft = () => {
        const targetDate = new Date("2024-08-22T21:58:18"); // Set your target date here
        const difference = +targetDate - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className="countdown-container">
            <h1 className="countdown-title">Season 6</h1>
            <div className="countdown">
            {Object.entries(timeLeft).map(([unit, value]) => (
                <div key={unit}>
                    <h2>{value}</h2>
                    <span>{unit}</span>
                </div>
            ))}
        </div>
        <button className='countdown-button' onClick={newssignup}>Let me know!</button>
        </div>
    );
}


export default Countdown;