import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import './App.css';
import About from './components/about';
import Watch from './components/watch';
import Footer from './components/Footer';

import Countdown from './components/Countdown'; // import the Countdown component

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Countdown />} /> 
            <Route path="/about" element={<About />} /> 
            <Route path="/watch" element={<Watch />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;