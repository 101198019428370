import React from 'react';
import './watch.css'; // make sure to import the CSS file
import Swal from 'sweetalert2';

function Watch() {


    const handleClickNetflix = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Not all seasons might be available on Netflix. Might require a paid subscription. Might not be available in your country.',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'info',
                    title: 'Redirecting...',
                    timer: 1000, // close the alert after 2 seconds
                }).then(() => {
                    window.location.href = 'https://www.netflix.com/title/80164216'; // redirect to Netflix
                });
            }
        });
    };

    const handleClickDisney = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'Not all seasons might be available on Disney+. Might require a paid subscription. Might not be available in your country.',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'info',
                    title: 'Redirecting...',
                    timer: 1000, // close the alert after 2 seconds
                }).then(() => {
                    window.location.href = 'https://www.disneyplus.com/series/miraculous-tales-of-ladybug-cat-noir/1CjTiHEJbLRC'; // redirect to Netflix
                });
            }
        });
    };

    const handleClickMiraculous = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'This site contains numerous inappropriate popup ads. We strongly recommend using an adblocker. Loading times may be also slow.',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'info',
                    title: 'Redirecting...',
                    timer: 1000, // close the alert after 2 seconds
                }).then(() => {
                    window.location.href = 'https://miraculous.to'; // redirect to Netflix
                });
            }
        });
    };

    return (
        <div className="container">
             <h2>Where to watch?</h2>
            <ul className="responsive-table">
                <li className="table-row">
                    <div className="col col-1">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/7/7a/Logonetflix.png" className="logo"/>
                    </div>
                    <div className="col col-2" data-label="Button">
                    <button className="button" onClick={handleClickNetflix}>Watch now!</button>
                    </div>
                </li>
                <li className="table-row">
                    <div className="col col-1" data-label="Logo">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Disney%2B_logo.svg/800px-Disney%2B_logo.svg.png" alt="logo" className="logo"/>
                    </div>
                    <div className="col col-2" data-label="Button">
                    <button className="button" onClick={handleClickDisney}>Watch now!</button>
                    </div>
                </li>
                <li className="table-row">
                    <div className="col col-1" data-label="Logo">
                        <h1> Miraculous.to </h1>
                    </div>
                    <div className="col col-2" data-label="Button">
                    <button className="button" onClick={handleClickMiraculous}>Watch now!</button>
                    </div>
                </li>
                <a className="disclaimer">Disclaimer: This website offers links to streaming services as a convenience. We have no affiliations with these services, and we cannot vouch for their legality. You are responsible for ensuring that you access content through legitimate means. If you suspect that any of these links may be in violation of any laws, kindly reach out to us at admin@miraculous.top. Please use these links with caution and at your own discretion.</a>
                
            </ul>
        </div>
    );
}

export default Watch;